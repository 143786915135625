import { useState } from 'react';

import CartoonContact from "../../images/CartoonContacto.png";
import './contact-form.css';
import RoundCheckbox from '../Common/RoundCheckbox/RoundCheckbox';
import ButtonDefault from '../Common/BtnDefault/ButtonDefault';
import Contact from '../../models/Contact';
import WhatsappAction from '../WhatsappAction/WhatsappAction';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    content: '',
  });

  const [isChecked, setChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
    setErrors({ ...errors, accept: '' });
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { from, email, content } = formData;
    const validationErrors = {};
    
    if (!isChecked) {
      validationErrors.accept = "Debes aceptar las politicas de uso y privacidad para enviar el mensaje";
    }

    if (!from) {
      validationErrors.from = "Digita un nombre";
    }

    if (!content) {
      validationErrors.content = "Digita el contenido del mensaje";
    }

    if (!email) {
      validationErrors.email = "Digita un correo";
    } else if (!validateEmail(email)) {
      validationErrors.email = "Dirección de correo no valida";
    }
    // Perform form validation here
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      sendContact(formData);
    }
  };

  const sendContact = async (data) => {
    const response = await Contact.sendEmail(data);
    if (response && response.result === 'success') {
      setFormData({ name: '', email: '', content: '' });
      setMessage("Tu mensaje ha sido enviado correctamente");
    } else {
      setMessage("Hubo un error al enviar el mensaje. Por favor intenta mas tarde");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="contact-form">
      
        <div>
          <img src={CartoonContact} alt="Contact" />
          <p>
            <b>UnirMENTES: </b> Servicios digitales que promueven el
            desarrollo del bienestar humano. Para más información déjanos tus
            datos y te contactaremos.
          </p>
        </div>
        <div className="form">
          <p>
            Cuéntanos qué necesidad de educación especializada y experta tienes
          </p>
          <input
            type="text"
            name="from"
            placeholder="Nombres y Apellidos"
            value={formData.from}
            onChange={handleChange}
          />
          {errors.from && <span className="error">{errors.from}</span>}
          <input
            type="text"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <span className="error">{errors.email}</span>}
          <textarea
            name="content"
            placeholder="Mensajes o Comentarios"
            value={formData.content}
            onChange={handleChange}
          />
          {errors.content && <span className="error">{errors.content}</span>}
          <div>
            <RoundCheckbox
              label="Acepto las politicas de uso y de privacidad (descargalas en el Pie de página)"
              checked={isChecked}
              onChange={handleCheckboxChange}
              name="accept"
            />
          </div>
          {errors.accept && <span className="error">{errors.accept}</span>}
          {message && <p><span className="success">{message}</span></p>}
          <div className="action-buttons">
            <ButtonDefault type="submit">Enviar</ButtonDefault>
            <WhatsappAction />
          </div>
        </div>
      </div>
    </form>
  )
}

export default ContactForm