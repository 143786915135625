import "./products.css";
import { products } from "../../data";
import ProductCard from "../../components/ProductCard/ProductCard";

const Products = () => {
  return (
    <>
      <header className="page-header page-header-title">Bienestar NET</header>
      <section className="page-content bienestar-net-intro">
      <p>
          En Bienestar NET puedes escoger la educación
          especializada que necesites: Educación experta estratégica psicológica,
          servicio de coach profesional en liderazgo y desarrollo de carrera,
          servicio profesional de wellness coach o health coach, orientación y
          apoyo escolar; educación experta estratégica financiera (inversión, tipos de
          activos, beneficios y riesgos, evaluación de empresas); asesoría y
          educación legal (exclusivo para Colombia: no incluye tributaria, ni
          penal).
        </p>
      </section>
      <section className="page-content products">
        <div className="product-cards-container">
          {products.map((product) => (
            <ProductCard key={product.title} {...product} />
          ))}
        </div>
      </section>
      <section className="page-content bienestar-net-description">
        
        <br />
        <p style={{ fontWeight: "bold" }}>
          La educación especializada y experta en Psicología se encuentra
          dirigida a:
        </p>
        <ol>
          <li>Personas que deseen mejorar su bienestar o salud mental.</li>
          <li>Empresas que deseen mejorar el bienestar de sus trabajadores.</li>
          <li>
            Profesionales que necesitan apoyo profesional para el desarrollo de
            sus labores en el área de salud o bienestar.
          </li>
        </ol>
        <br />
        <p style={{ fontWeight: "bold" }}>
          La educación especializada y experta en finanzas se encuentra
          dirigida a:
        </p>

        <ol>
          <li>
            Personas que deseen mejorar su calidad de vida a través de la
            inversión.
          </li>
          <li>
            Personas que deseen mejorar el manejo de sus finanzas personales.
          </li>
          <li>
            Empresas que deseen mejorar el bienestar de sus trabajadores a
            través de educación financiera.
          </li>
        </ol>

        <br />
        <p style={{ fontWeight: "bold" }}>
          La asesoría legal especializada y experta se encuentra dirigida a:
        </p>
        <ol>
          <li>
            Personas que tienen dudas acerca de aspectos legales que pueden
            afectar su bienestar en el momento presente o futuro.
          </li>
          <li>Empresas que deseen mejorar el bienestar de sus trabajadores.</li>
        </ol>
      </section>
    </>
  );
};

export default Products;
