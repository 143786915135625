
import { FaWhatsapp } from 'react-icons/fa';
import { waConfig } from '../../data';
import './whatsapp-action.css'

const WhatsappAction = () => {
  const handleWAClick = () => {
    window.open(waConfig.url + waConfig.phone, '_blank');
  };

  return (
    <div className='wa-icon' onClick={handleWAClick}>
      <FaWhatsapp />
    </div>
  );
};

export default WhatsappAction;