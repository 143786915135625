import { useState } from 'react'
import ButtonSeeMore from '../Common/BtnSeeMore/ButtonSeeMore'
import './servicecard.css'
import Modal from '../Modal/Modal';
import { Link } from 'react-router-dom';


const ServiceCard = ({title, content, addButton, actionLink, ...props}) => {

    const [isModalOpen, setModalOpen] = useState(false);

    const handleModalOpenChange = () => {
        setModalOpen(!isModalOpen);
    };

    return (
        <>
            <div className="serviceCard">
                <h5 className={!addButton ? 'text-clickeable': ''} onClick={!addButton ? () => setModalOpen(true) : () => {}}>{title}</h5>
                <p>{content}</p>
                {
                    addButton && (<ButtonSeeMore title="Ver Más" onClick={() => setModalOpen(true)}/>)  
                }
                <hr/>
            </div>
            {
                props.children && <Modal isOpen={isModalOpen} setIsOpen={handleModalOpenChange}>
                    {props.children}
                    {
                        actionLink && (<Link className='service-card-action' to={actionLink.link} target='_blank'>{actionLink.label}</Link>)
                    }
                </Modal>
            }
        </>
    )
}

ServiceCard.defaultProps = {
    addButton: false,
    actionLink: {},
    title: "",
    content: ""
}

export default ServiceCard