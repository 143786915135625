import './buttonseemore.css'
import {BsPlusLg} from 'react-icons/bs'

const ButtonSeeMore = ({title, onClick}) => {
    return (
        <div className="button-seemore" onClick={onClick}>
            {title} <span><BsPlusLg/></span>
        </div>
    )
}

ButtonSeeMore.defaultProps = {
    onClick: () => {},
    title: "Action"
}

export default ButtonSeeMore