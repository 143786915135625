import axios from 'axios';
import { apiKey, apiUrl } from '../data';

axios.defaults.headers.common['Authorization'] = `${apiKey}`;

export default class Article {

    static async getArticles(page, perPage) {
        try {
            const response = await axios.get(`${apiUrl}/public/articles/paginated/${page}/${perPage}`);

            if (response.status !== 200) {
                return {};
            }

            return response.data;
        } catch (e) {
            console.log("Exception getting articles ", e);
        }
        return {};
    }

    static async getArticle(articleUrl) {
        try {
            const response = await axios.get(`${apiUrl}/public/articles/${articleUrl}`);

            console.log("response", response);
            if (response.status !== 200) {
                return {};
            }

            return response.data;
        } catch (e) {
            console.log("Exception getting article "+articleUrl, e);
        }
        return {};
    }
}