import { useEffect, useState } from 'react';
import './blog.css'
import BlogCard from '../../components/BlogCard/BlogCard';
import Article from '../../models/Article';

const Blog = () => {
    const [articles, setArticles] = useState({});
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchArticles(page);
    }, [page]);

    const fetchArticles = async (currentPage) => {
        setLoading(true);
        const fetchedArticles = await Article.getArticles(currentPage, 9);

        if (fetchedArticles) {
            setArticles(fetchedArticles);
        }
        setLoading(false);
    };

    const handlePageChange = (plus) => {
        if (!articles) {
            return;
        }
        const newPage = page + plus;
        if (newPage < 1) {
            return;
        }
        if (newPage > articles.last_page) {
            return;
        }
        setPage(newPage);
    }

    return (
        <>
            <header className="page-header blog-header">
                <h1>BLOG</h1>
            </header>
            <section className='page-content blog-page'>
                <div className="cards-container">
                    {
                        loading && <div className="loading">Cargando...</div>
                    }
                    {(articles?.data ?? []).map((article) => (
                        <BlogCard
                            key={article.id}
                            img={article.image
                                ? `data:${article.image.mime_type};base64,${article.image.thumbnail_image_base64}`
                                : null}
                            title={article.title}
                            author={article.user.name}
                            date={article.created_at}
                            summary={article.summary}
                            authorUrl={article.user.email}
                            url={`/blog/${article.url_path}`}
                            credits={article.image?.credits}
                        />
                    ))}
                </div>
                <div className="pagination-buttons">
                    <button className="pagination-button" onClick={() => handlePageChange(-1)}>Anterior</button>
                    <button className="pagination-button" onClick={() => handlePageChange(1)}>Siguiente</button>
                </div>
                
            </section>
        </>
    )
}

export default Blog