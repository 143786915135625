import Graciela from "./images/fotos-quienes-somos/graciela.png";
import Jaime from "./images/fotos-quienes-somos/jaime.png";
import Laura from "./images/fotos-quienes-somos/laura.png";
import Yurani from "./images/fotos-quienes-somos/yurani.png";
import Diana from "./images/fotos-quienes-somos/diana.png";
import David from "./images/fotos-quienes-somos/david.png";

import Review1img from "./images/reviews/Review1.png";
import Review2img from "./images/reviews/Review2.png";
import Review3img from "./images/reviews/Review3.png";

export const beHost = process.env.REACT_APP_API_URL;
export const apiUrl = `${beHost}/api/v1`;
export const apiKey = "mMa3QKNlxh03SOPnoFughSn65pBMRP2ftt8S";

export const waConfig= {
    phone: '573204898974',
    url: 'https://wa.me/',
}

export const links = [
  {
    name: "Nuestros servicios",
    path: "services",
    type: "scroll",
  },
  {
    name: "¿Quiénes somos?",
    path: "/quienes-somos",
    type: "link",
  },
  {
    name: "Bienestar NET",
    path: "/bienestar-net",
    type: "link",
  },
  {
    name: "Blog",
    path: "/blog",
    type: "link",
  },
  {
    name: "Contacto",
    path: "contact",
    type: "scroll",
  },
  {
    name: "Regala bienestar",
    path: "https://app.unirmentes.com/campaigns",
    type: "link",
    style: "highlight",
  },
];

export const articles = [
  {
    id: 1,
    title: "Articulo 1",
    summary:
      "Nostrud velit deserunt elit labore id voluptate qui ad proident amet ex ullamco do.",
    body: "Est duis officia elit tempor. Deserunt eiusmod reprehenderit veniam dolor fugiat dolore cillum Lorem in officia irure exercitation quis ipsum. Eiusmod est aliquip tempor laborum non consectetur ut dolore et culpa enim et laborum. Qui nulla incididunt ut ut Lorem nostrud velit proident dolor enim nulla aliqua ea nulla. Esse consectetur aute do tempor laborum cillum exercitation mollit in incididunt enim aliquip. Nostrud pariatur ullamco aliquip reprehenderit duis ullamco. Id nostrud duis ad esse aute ipsum.",
    path: "/article-1",
    img: Review1img,
    author: "Graciela Pardo",
    date: "Marzo 19,2023",
    authorUrl:
      "https://www.linkedin.com/in/david-mauricio-beltr%C3%A1n-pardo-91743470/",
  },
  {
    id: 2,
    title: "Articulo 2",
    summary:
      "Nostrud velit deserunt elit labore id voluptate qui ad proident amet ex ullamco do.",
    body: "Est duis officia elit tempor. Deserunt eiusmod reprehenderit veniam dolor fugiat dolore cillum Lorem in officia irure exercitation quis ipsum. Eiusmod est aliquip tempor laborum non consectetur ut dolore et culpa enim et laborum. Qui nulla incididunt ut ut Lorem nostrud velit proident dolor enim nulla aliqua ea nulla. Esse consectetur aute do tempor laborum cillum exercitation mollit in incididunt enim aliquip. Nostrud pariatur ullamco aliquip reprehenderit duis ullamco. Id nostrud duis ad esse aute ipsum.",
    path: "/article-2",
    img: Review2img,
    author: "Graciela Pardo",
    date: "Diciembre 24,2022",
    authorUrl:
      "https://www.linkedin.com/in/david-mauricio-beltr%C3%A1n-pardo-91743470/",
  },
  {
    id: 3,
    title: "Articulo 3",
    summary:
      "Nostrud velit deserunt elit labore id voluptate qui ad proident amet ex ullamco do.",
    body: "Est duis officia elit tempor. Deserunt eiusmod reprehenderit veniam dolor fugiat dolore cillum Lorem in officia irure exercitation quis ipsum. Eiusmod est aliquip tempor laborum non consectetur ut dolore et culpa enim et laborum. Qui nulla incididunt ut ut Lorem nostrud velit proident dolor enim nulla aliqua ea nulla. Esse consectetur aute do tempor laborum cillum exercitation mollit in incididunt enim aliquip. Nostrud pariatur ullamco aliquip reprehenderit duis ullamco. Id nostrud duis ad esse aute ipsum.",
    path: "/article-3",
    img: Review3img,
    author: "Graciela Pardo",
    date: "Noviembre 04,2022",
    authorUrl:
      "https://www.linkedin.com/in/david-mauricio-beltr%C3%A1n-pardo-91743470/",
  },
];

export const team = [
  {
    id: 1,
    name: "Graciela Pardo Angarita",
    img: Graciela,
    description:
      "Psicóloga, Universidad Catolica de Colombia. Especialista en Psicología Clínica, Experimental y de la Salud de la  Universidad Nacional de Colombia,  Profesora (p) del Departamento de Psicologia de la Universidad Nacional de Colombia, Gerente de Digital Psychology Solutions  S.A.S.<br>Experta en  Psicología clínica y de la Salud, Promoción y Prevención en Salud, calidad de los servicios en salud y Servicios de Psicología en modalidad Telemedicina y Telesalud. Con experiencia en salud hospitalaria, ambulatoria y comunitaria y en  diseño de soluciones tecnológicas en Telepsicología. Co-autora de las plataformas tecnológicas Tel-Psyc, Psy-One v4 y Bienestar NET.",
    path: "/servicios",
    linkedin: "",
  },
  {
    id: 2,
    name: "Jaime Beltran Pardo",
    img: Jaime,
    description:
      "Ingeniero Electrónico, Msc en Ingeniería de Sistemas de la Universidad Nacional de Colombia. Con mas de 10 años de experiencia en programación y desarrollo de software de tipo web y en diseño de arquitectura de software.<br>Experto en desarrollo WEB usando PHP, MySQL, Javascript, Angular, Nodejs, MongoDB; en configuración de servidores y servicios en la nube Amazon -AWS, IBM softlayer, IBM Bluemix, DigitalOcean; en diseño de bases de datos e ingeniería de software en general (Modelos de ciclo de vida, requerimientos funcionales, codificación, integración y prueba).",
    path: "/quienes-somos",
    linkedin: "https://www.linkedin.com/in/jaime-beltran-p/",
  },
  {
    id: 3,
    name: "Laura Elisa Andrade",
    img: Laura,
    description:
      "Economista Escuela Colombiana de Ingeniería,  Msc en Economía, Pontificia Universidad Javeriana. Msc,  Finance Hamburg School of Business Adiministration(HSBA). Actualmente trabaja como Investigadora Blockchain con énfasis en activos digitales y tiene más de 10 años de experiencia en el área financiera. Ha trabajado en el sector de tecnología, financiero y asegurador en roles de investigación y liderando proyectos innovadores.",
    path: "/blog",
    linkedin: "https://www.linkedin.com/in/laura-andrade-188409142 ",
  },
  {
    id: 4,
    name: "Yurani Murcia Galindo",
    img: Yurani,
    description:
      "Psicóloga, Universidad Nacional de Colombia. Administradora Pública egresada de la Escuela Superior de Administración Pública (ESAP). Especialista  en Gerencia de Proyectos con metodología PMI. Formadora coaching convenio Mintic- Universidad Nacional de Colombia. Con experiencia en C.Sociego, IDIPRON,  CRAC.  Coordinadora de equipos de Bienestar de la Policía Nacional.  Con experiencia en la planeación, seguimiento y ejecución de proyectos clínicos y de salud mental, educativos y sociales con población en condición de habitabilidad en calle, discapacidad, alta vulnerabilidad y fragilidad social.",
    path: "/contacto",
    linkedin: "https://www.linkedin.com/in/yurany-murcia-galindo-7aa43294/",
  },
  {
    id: 5,
    name: "Diana Guarin Salazar",
    img: Diana,
    description:
      "Administradora Pública egresada de la Escuela Superior de Administración Pública (ESAP). Msc en Desarrollo Educativo y Social de la Fundación Centro Internacional de Educación y Desarrollo Humano en convenio con la Universidad Pedagógica Nacional (CINDE- UPN).Miembro de grupo de investigación KENTA de la Facultad de Ciencia Tecnología de la Universidad Pedagógica Nacional.Socia y Gerente Administrativa de Digital Psychology Solutions SAS.",
    path: "/contacto",
    linkedin:
      "https://www.linkedin.com/in/diana-roc%C3%ADo-guar%C3%ADn-salazar-a83290187/",
  },
  {
    id: 6,
    name: "David Mauricio Beltran",
    img: David,
    description:
      "Ingeniero de Sistemas de la Escuela Colombiana de Ingeniería. Msc en Ciencias de la Información y Comunicaciones. Universidad Distrital Francisco José de Caldas. Con experiencia en desarrollo de software  en diferentes plataformas entre las que se encuentran, AS400, páginas web, aplicaciones de escritorio, aplicaciones móviles desde el año 2004 y amplia experiencia  en desarrollo multiplataforma así como en varias industrias del sector financiero.",
    path: "/contacto",
    linkedin:
      "https://www.linkedin.com/in/david-mauricio-beltr%C3%A1n-pardo-91743470/",
  },
];

export const reviews = [
  {
    id: 1,
    content:
      "Est duis officia elit tempor. Deserunt eiusmod reprehenderit veniam dolor fugiat dolore cillum Lorem in officia irure exercitation quis ipsum. Eiusmod est aliquip tempor laborum non consectetur ut dolore et culpa enim et laborum. Qui nulla incididunt ut ut Lorem nostrud velit proident dolor enim nulla aliqua ea nulla. Esse consectetur aute do tempor.",
    img: Review1img,
    author: "Graciela Pardo",
    date: "Marzo 19,2023",
  },
  {
    id: 2,
    content:
      "Est duis officia elit tempor. Deserunt eiusmod reprehenderit veniam dolor fugiat dolore cillum Lorem in officia irure exercitation quis ipsum. Eiusmod est aliquip tempor laborum non consectetur ut dolore et culpa enim et laborum. Qui nulla incididunt ut ut Lorem nostrud velit proident dolor enim nulla aliqua ea nulla. Esse consectetur aute do tempor laborum cillum exercitation mollit in incididunt enim aliquip. Nostrud pariatur ullamco aliquip reprehenderit duis ullamco. Id nostrud duis ad esse aute ipsum.",
    img: Review2img,
    author: "Graciela Pardo",
    date: "Diciembre 24,2022",
  },
  {
    id: 3,
    content:
      "Est duis officia elit tempor. Deserunt eiusmod reprehenderit veniam dolor fugiat dolore cillum Lorem in officia irure exercitation quis ipsum. Eiusmod est aliquip tempor laborum non consectetur ut dolore et culpa enim et laborum. Qui nulla incididunt ut ut Lorem nostrud velit proident dolor enim nulla aliqua ea nulla. Esse consectetur aute do tempor laborum cillum exercitation mollit in incididunt enim aliquip. Nostrud pariatur ullamco aliquip reprehenderit duis ullamco. Id nostrud duis ad esse aute ipsum.",
    img: Review3img,
    author: "Graciela Pardo",
    date: "Noviembre 04,2022",
  },
];

export const products = [
  {
    title: "Pago por sesión",
    price: 140000,
    valueText: "Para empezar",
    description: "Adquiere una hora de sesión de cualquiera de los servicios.",
    details: [
      "Educación experta estratégica (Wellness)",
      "Educación experta estratégica (Health)",
      "Educación experta estratégica en Inteligencia Financiera (Financial Intelligence)",
      "Educación experta estratégica en sentido de propósito de vida (Purpose of life)",
    ],
    disclaimer: "",
    buttonLink: "https://app.unirmentes.com",
    buttonText: "Comprar",
    boxColor: "#e8eaf3",
  },
  {
    title: "Paquete de 3 sesiones",
    price: 360000,
    valueText: "Mas servicios, menor precio",
    description: "3 sesiones de una hora (1) de duración. Puedes escoger la asesoría que desees y combinarlas.",
    details: [
      "Educación experta estratégica (Wellness)",
      "Educación experta estratégica (Health)",
      "Educación experta estratégica en Inteligencia Financiera (Financial Intelligence)",
      "Educación experta estratégica en sentido de propósito de vida (Purpose of life)",
    ],
    disclaimer: "Valido hasta 2025",
    buttonLink: "https://app.unirmentes.com/campaign/1",
    buttonText: "Comprar",
    boxColor: "#ffddf1",
  },
  {
    title: "Paquete de 2 sesiones",
    price: 260000,
    valueText: "Continuidad y ahorro",
    description: "2 sesiones de una hora (1) de duración. Puedes escoger la asesoría que desees y combinarlas.",
    details: [
      "Educación experta estratégica (Wellness)",
      "Educación experta estratégica (Health)",
      "Educación experta estratégica en Inteligencia Financiera (Financial Intelligence)",
      "Educación experta estratégica en sentido de propósito de vida (Purpose of life)",
    ],
    disclaimer: "Valido hasta 2025",
    buttonLink: "https://app.unirmentes.com/campaign/2",
    buttonText: "Comprar",
    boxColor: "#e8eaf3",
  },
];
