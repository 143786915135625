import React from 'react';
import './RoundCheckbox.css';

const RoundCheckbox = ({ label, checked, name, onChange }) => {
  return (
    <label className="round-checkbox">
      <input
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span className="checkbox-label">{label}</span>
    </label>
  );
};

export default RoundCheckbox;
