import {BrowserRouter, Routes, Route} from 'react-router-dom'


import './App.css';
import Home from './pages/home/Home';
import AboutUs from './pages/aboutus/AboutUs';
import NotFound from './pages/notFound/NotFound';
import Blog from './pages/blog/Blog';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import BlogPost from './pages/blog/BlogPost';
import Products from './pages/products/Products';

const App = () => {
  return (
    <BrowserRouter>
      <Navbar/>
      <div className='container full'>
        <Routes>
          <Route index element={<Home/>}/>
          <Route path='quienes-somos' element={<AboutUs/>}/>
          <Route path='bienestar-net' element={<Products/>}/>
          <Route path='blog' element={<Blog/>}/>
          <Route path='blog/:postPath' element={<BlogPost/>}/>
          <Route path='*' element={<NotFound/>}/>
        </Routes>
      </div>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
