import './credited-image.css';

const CreditedImage = (props) => {
  return (
    <>
      <div className="credited-image">
        <img src={props.src} alt={props.alt} />
        { props.credits && <span>{props.credits}</span> }
      </div>
    </>
  )
}

export default CreditedImage