import axios from 'axios';
import { apiKey, apiUrl } from '../data';

axios.defaults.headers.common['Authorization'] = `${apiKey}`;

export default class Contact {

    static async sendEmail(data) {
        try {
            const response = await axios.post(`${apiUrl}/public/send-contact-email`, data);

            if (response.status !== 200) {
                return {};
            }

            return response.data;
        } catch (e) {
            console.log("Exception getting data", e);
        }
        return {data: []};
    }
}