import ButtonSeeMore from '../Common/BtnSeeMore/ButtonSeeMore'
import { BsLinkedin } from 'react-icons/bs'
import Modal from '../Modal/Modal';
import './membercard.css'
import { useState } from 'react';


const MemberCard = ({name, img, linkedin, ...props}) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const handleModalOpenChange = () => {
        setModalOpen(!isModalOpen);
    };

    const openLinkedin = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    return (
        <>
            <div className="member-card">
                <img src={img} alt={name}/>
                <h5>{name}</h5>
                <div className="member-btn">
                    <ButtonSeeMore title="Ver Más" onClick={() => setModalOpen(true)}/>
                    <span className="linkedin" onClick={() => openLinkedin(linkedin)}><BsLinkedin/></span>
                </div>
            </div>
            {
                props.children && <Modal isOpen={isModalOpen} setIsOpen={handleModalOpenChange}>
                    <div dangerouslySetInnerHTML={{ __html: props.children }}/>
                </Modal>
            }
        </>
    )
}

MemberCard.defaultProps = {
    name: "",
    img: "",
    linkedin: ""
}

export default MemberCard

