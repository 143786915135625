import './aboutus.css'
import Banner from '../../images/banner-landing-DIGITALmente-design-reduced.jpg'
import { team } from "../../data";
import MemberCard from '../../components/MemberCard/MemberCard';

const AboutUs = () => {
  return (
    <>
      <header className="page-header">
        <img src={Banner} alt="AboutUs banner" />
      </header>
      <section className='page-content about-us'>
        <p>
          Dado que las problemáticas relacionadas con el bienestar humano y la  salud mental son cada vez más frecuentes a nivel mundial y que esta realidad puede evitarse a través del acceso temprano y oportuno a servicios de fomento del bienestar humano o de la salud mental de alta calidad, DIGITAL PSYCHOLOGY Solutions nace como una respuesta a tal necesidad.
        </p>
        <p>
          Trabajamos día a día para lograr la satisfacción de las necesidades de desarrollo personal o profesional de personas y empresas. Desarrollamos y adaptamos tecnologías que promueven el desarrollo humano y brindamos servicios que contribuyen al mejoramiento del mismo. Así es, nuestra labor consiste en UnirMENTES para encontrar soluciones creativas y realistas que contribuyan al fomento del bienestar humano y la salud mental. También nos interesa el mejoramiento de las prácticas profesionales y la calidad de la atención en servicios de fomento del bienestar humano y la salud.
        </p>
      </section>
      <section className="section-content team" id="team">
        <h3>Equipo Fundador</h3>
        <p>
          Somos un equipo profesional de emprendedores y amigos, que motivados por hacer la diferencia en bienestar humano,
          trabajamos en el desarrollo de soluciones y servicios digitales que contribuyan al desarrollo del potencial humano, el
          bienestar y la salud mental de personas, profesionales y empresas.
        </p>
        <div className="members">
          {team.map((member) => (
            <MemberCard key={member.name} img={member.img} name={member.name} linkedin={member.linkedin}>
              {member.description}
            </MemberCard>
          ))}
        </div>
      </section>
    </>
  )
}

export default AboutUs