import { MdOutlineClose } from 'react-icons/md'
import './modal.css'
import { useEffect } from 'react';


const Modal = ({isOpen, setIsOpen, ...props}) => {

    useEffect(() => {
        const body = document.body;
        if (isOpen) {
          body.style.overflow = 'hidden';
        } else {
          body.style.overflow = 'unset';
          body.style.overflowX = 'hidden';
        }
    
        // Re-enable page scroll when the component unmounts or modal is closed
        return () => {
          body.style.overflow = 'unset';
        };
    }, [isOpen]);

    return (
        <>
            {
                isOpen &&
                <>
                    <div className="darkBG" onClick={() => setIsOpen(false)}></div>
                    <div className="modal-centered">
                        <div className='modal'>
                            <span className='close' onClick={() => setIsOpen(false)}><MdOutlineClose color='#2b3780'/></span>
                            <div className='content scroll-y'>
                                {props.children}
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

Modal.defaultProps = {
    setIsOpen: () => {},
    isOpen: false
}

export default Modal