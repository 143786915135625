import "./product-card.css";
import { Link } from "react-router-dom";

const ProductCard = ({
  title,
  price,
  valueText,
  details,
  disclaimer,
  buttonLink,
  buttonText,
  boxColor,
  description,
}) => {
  
  return (
    <div className="product-card">
      <div className="product-card-content" style={{ backgroundColor: boxColor }}>
        <div className="product-card-header">
          <h3 className="product-card-title">{title}</h3>
          <p className="product-card-price">${price} COP + IVA</p>
        </div>
        <div className="product-card-value">
          <p className="product-card-value-text">{valueText}</p>
          <p className="product-card-disclaimer">{disclaimer}</p>
        </div>
        <div className="product-card-button-wrapper">
          <Link className="product-card-button" to={buttonLink} target="_blank">
            {buttonText}
          </Link>
        </div>
      </div>
      <div className="product-card-details-wrapper">
        <p className="product-card-details-description">{description}</p>
        <ul className="product-card-details">
          {details.map((detail) => (
            <li key={detail}>{detail}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

ProductCard.defaultProps = {
  title: "",
  price: 0,
  valueText: "",
  details: [],
  disclaimer: "",
  buttonLink: "",
  buttonText: "",
  boxColor: "",
  description: "",
};

export default ProductCard;
