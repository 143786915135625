import ButtonSeeMore from '../Common/BtnSeeMore/ButtonSeeMore';
import './blogcard.css';
import noImageIcon from "../../images/icons8-no-image-100.png";
import CreditedImage from '../CreditedImage/CreditedImage';


const BlogCard = (props) => {
    const date = new Date(props.date);

    // Format the Date object into 'Y-m-d' format
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JavaScript
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    const urlLink = `${props.url}`;

    const goToArticle = () => {
        window.location.href = urlLink;
    }
    return (
        <div className="blogCard">
            <CreditedImage src={props.img ?? noImageIcon} alt={props.title} credits={props.credits}/>
            <span className='author'>
                Por {props.author}
            </span>
            <span className='date'>| {formattedDate ?? ''}</span>
            <h5><a href={urlLink}>{props.title}</a></h5>
            <p>{props.summary}</p>
            <ButtonSeeMore title="Leer Más" onClick={goToArticle}/>  
            <hr/>
        </div>
    )
}

export default BlogCard