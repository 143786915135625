import { useEffect, useState } from 'react';
import './blog-post.css'
import Article from '../../models/Article';
import { useParams } from 'react-router-dom';
import CreditedImage from '../../components/CreditedImage/CreditedImage';

const BlogPost = () => {
    const { postPath } = useParams();
    const [article, setArticle] = useState({});
    const [loading, setLoading] = useState(false);

    console.log("postPath", postPath);

    useEffect(() => {
        fetchArticle(postPath);
    }, [postPath]);

    const fetchArticle = async (path) => {
        setLoading(true);
        const article = await Article.getArticle(path);

        if (article) {
            setArticle(article);
        }
        setLoading(false);
    };

    const parseDate = (isoDateString) => {
        if (!isoDateString) {
            return '';
        }
        const date = new Date(isoDateString);

        if (!date) {
            return '';
        }

        // Format the Date object into 'Y-m-d' format
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JavaScript
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    return (
        <>
            <header className="page-header post-header">
                <h1>{article.title}</h1>
                {!loading && !article?.id && <h1 className="not-found">Artículo no encontrado</h1>}
            </header>
            <section className='page-content'>
                {loading && <div className="loading">Cargando...</div>}
                
                {article && article.id && (
                    <>
                        <div className="post-info">
                        <div className="author">
                            <span>{article.user?.name}</span> |
                            <span> {parseDate(article.created_at)}</span>
                        </div>
                        <CreditedImage
                            src={article.image
                            ? `data:${article.image.mime_type};base64,${article.image.thumbnail_image_base64}` : ''}
                            alt={article.title}
                            credits={article.image?.credits}
                        />

                        </div>
                        <div className="post-text">
                            <div dangerouslySetInnerHTML={{ __html: article.content }} />
                        </div>
                    </>
                )}
                
            </section>
        </>
    )
}

export default BlogPost